<template>
    <div :class="$pad? 'padder' : $platform == 'ios' ? 'small_padding' : '' ">
        <slot></slot>
    </div>
</template>

<script>
export default {
    computed: {
        pad() {
            return this.$pad
        },
    },
};
</script>

<style scoped lang="sass">
.padder
    padding-top: 20px
.small_padding
    margin-top: 0px 
</style>