<template>
<!-- in ios uncomment the wrapper -->
  <!-- <wrapper> -->
    <v-app class="">
      <default-bar/>
      <default-view />
      <default-navigation />
    </v-app>
  <!-- </wrapper> -->
</template>

<script>
import Wrapper from './wraper.vue';
export default {
  name: "DefaultLayout",

  components: {
    DefaultView: () =>
      import(
        /* webpackChunkName: "default-view" */
        "./Body/View"
      ),
    DefaultBar: () =>
      import(
        /* webpackChunkName: "default-view" */
        "./Appbar/AppBar"
      ),
    DefaultNavigation: () =>
      import(
        /* webpackChunkName: "default-navigation" */
        "./Navigation/index"
      ),
    Wraper: () =>
      import(
        /* webpackChunkName: "default-navigation" */
        "./wraper.vue"
      ), 
    Wrapper
  }
};
</script>

  